import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../context/LanguageContext';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  const { t } = useLanguage();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsMobileDropdownOpen(false);
  };

  const toggleMobileDropdown = (e) => {
    e.preventDefault();
    setIsMobileDropdownOpen(!isMobileDropdownOpen);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    window.location.href = 'https://courses.beharv.com';
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <button
          className={`hamburger-menu ${isMenuOpen ? 'active' : ''}`}
          onClick={toggleMenu}
          aria-label="Menu"
        >
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
        </button>

        <div className={`mobile-menu ${isMenuOpen ? 'active' : ''}`}>
          <div className="mobile-nav-items">
            <ul>
              <li>
                <Link to="/" onClick={closeMenu}>
                  {t('navigation.menu.home')}
                </Link>
              </li>
              <li>
                <Link to="/courses" onClick={closeMenu}>
                  {t('navigation.menu.courses')}
                </Link>
              </li>
              <li className="dropdown">
                <button className="dropdown-button" onClick={toggleMobileDropdown}>
                  {t('navigation.menu.institutional')}
                </button>
                <ul className={`dropdown-menu ${isMobileDropdownOpen ? 'active' : ''}`}>
                  <li><Link to="/about" onClick={closeMenu}>{t('navigation.menu.about')}</Link></li>
                  <li><Link to="/terms" onClick={closeMenu}>{t('navigation.menu.terms')}</Link></li>
                  <li><Link to="/privacy" onClick={closeMenu}>{t('navigation.menu.privacy')}</Link></li>
                  <li><Link to="/contact" onClick={closeMenu}>{t('navigation.menu.contact')}</Link></li>
                </ul>
              </li>
              <li>
                <a href="https://courses.beharv.com" className="cta-button" onClick={closeMenu}>
                  {t('navigation.menu.login')}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <ul className="nav-menu">
          <li><Link to="/">{t('navigation.menu.home')}</Link></li>
          <li><Link to="/courses">{t('navigation.menu.courses')}</Link></li>
          <li className="dropdown">
            <button className="dropdown-button">
              {t('navigation.menu.institutional')}
            </button>
            <ul className="dropdown-menu">
              <li><Link to="/about">{t('navigation.menu.about')}</Link></li>
              <li><Link to="/terms">{t('navigation.menu.terms')}</Link></li>
              <li><Link to="/privacy">{t('navigation.menu.privacy')}</Link></li>
              <li><Link to="/contact">{t('navigation.menu.contact')}</Link></li>
            </ul>
          </li>
        </ul>

        <div className="nav-buttons">
          <a href="https://courses.beharv.com" className="cta-button">
            {t('navigation.menu.login')}
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 