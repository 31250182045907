import React, { createContext, useState, useContext, useEffect } from "react";
import { translations } from "../translations";

const LanguageContext = createContext();

const SUPPORTED_LANGUAGES = ["en", "es", "pt-br"];
const DEFAULT_LANGUAGE = "en";

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    // Get initial language from localStorage or browser preference or default to 'en'
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage && SUPPORTED_LANGUAGES.includes(savedLanguage)) {
      return savedLanguage;
    }

    // Check browser language preference
    const browserLang = navigator.language.toLowerCase();
    const preferredLang = SUPPORTED_LANGUAGES.find(
      (lang) =>
        browserLang.startsWith(lang) ||
        browserLang.startsWith(lang.split("-")[0])
    );

    return preferredLang || DEFAULT_LANGUAGE;
  });
  const [forceUpdate, setForceUpdate] = useState(0);

  // Save language preference to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("language", currentLanguage);
  }, [currentLanguage]);

  const value = {
    language: currentLanguage,
    setLanguage: (lang) => {
      if (SUPPORTED_LANGUAGES.includes(lang)) {
        setCurrentLanguage(lang);
        setForceUpdate((prev) => prev + 1);
        // Force a re-render of components using translations
        window.dispatchEvent(new Event("languageChange"));
      } else {
        console.warn(`Unsupported language: ${lang}`);
      }
    },
    forceUpdate,
    t: (key, params = {}) => {
      try {
        const keys = key.split(".");
        let translation = translations[currentLanguage];

        for (const k of keys) {
          if (!translation || !translation[k]) {
            console.warn(
              `Translation missing for key: ${key} in language: ${currentLanguage}`
            );
            return key; // Return the key if translation is missing
          }
          translation = translation[k];
        }

        // Handle string interpolation if params are provided
        if (typeof translation === "string" && Object.keys(params).length > 0) {
          return translation.replace(/\{(\w+)\}/g, (match, key) => {
            return params[key] !== undefined ? params[key] : match;
          });
        }

        return translation;
      } catch (error) {
        console.error(`Error getting translation for key: ${key}`, error);
        return key;
      }
    },
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
