export const translations = {
  en: {
    nav: {
      home: "Home",
      courses: "Courses",
      about: "About",
      contact: "Contact",
      login: "Login",
      register: "Register",
    },
    hero: {
      title: {
        part1: "Unlock Your",
        highlight: "Potential",
        part2: "Transform Your Future",
      },
      subtitle:
        "Join thousands of students who are already learning and advancing their careers with our professional courses.",
      buttons: {
        explore: "Explore Courses",
        learnMore: "Learn More",
      },
      stats: {
        students: "Students",
        courses: "Courses",
        successRate: "Success Rate",
      },
      cards: {
        certification: "Professional Certification",
        expertLed: "Expert-Led Courses",
      },
    },
    features: {
      title: "Professional Certification",
      onlineLearning: {
        title: "Online Learning",
        description: "Study at your own pace from anywhere in the world",
      },
      certification: {
        title: "Professional Certification",
        description:
          "Receive a recognized certificate upon completion of your courses",
      },
      expertInstructors: {
        title: "Expert Instructors",
        description:
          "Learn from industry professionals with years of experience",
      },
    },
    courseAccess: {
      title: "Start Your Learning Journey",
      subtitle:
        "Access our platform and discover a new way of learning. With free courses and recognized certificates, you're one step away from transforming your professional future.",
      features: {
        certification: {
          title: "Certified Courses",
          description: "Get recognized certificates",
        },
        pace: {
          title: "Learn at Your Pace",
          description: "Flexible learning schedule",
        },
        access: {
          title: "Access Anywhere",
          description: "Study from any device",
        },
      },
      button: "Start Learning Now",
    },
    categories: {
      title: "Explore by Category",
      subtitle: "Discover a wide range of courses across various categories",
      items: {
        beauty: {
          title: "Beauty and Aesthetics",
          slug: "beauty-and-aesthetics",
        },
        health: {
          title: "Health",
          slug: "health",
        },
        it: {
          title: "Information Technology",
          slug: "information-technology",
        },
        admin: {
          title: "Administration",
          slug: "administration-and-management",
        },
        languages: {
          title: "Languages",
          slug: "languages",
        },
      },
    },
    reviews: {
      title: "What Our Students Say",
      subtitle:
        "Discover how our courses have helped students achieve their goals",
      reviewers: {
        clara: {
          name: "Clara",
          role: "Designer",
          text: "It's the perfect place for anyone who wants to invest in their professional future with confidence.",
        },
        nelson: {
          name: "Nelson",
          role: "Musician",
          text: "I've already taken 8 courses at Beharv, and I'm already thinking about the next one!",
        },
        aline: {
          name: "Aline",
          role: "Nurse",
          text: "I needed to specialize and get the certification, here it helped me a lot.",
        },
      },
    },
    navigation: {
      menu: {
        home: "Home",
        courses: "Courses",
        institutional: "Institutional Links",
        about: "About Us",
        terms: "Terms and Conditions",
        privacy: "Privacy Policy",
        contact: "Contact",
        login: "Login",
      },
    },
    footer: {
      brand: {
        description:
          "Your free online courses platform. Learn, develop yourself and achieve your professional goals.",
      },
      sections: {
        institutional: {
          title: "Institutional Links",
          about: "About Us",
          terms: "Terms and Conditions",
          privacy: "Privacy Policy",
          contact: "Contact",
        },
        useful: {
          title: "Useful Links",
          panel: "User Panel",
          courses: "Courses List",
          social: "Social Media",
        },
      },
      copyright: "© 2025 Beharv. All rights reserved.",
    },
    courses: {
      search: {
        placeholder: "Search courses...",
        sortBy: "Sort by",
        sortOptions: {
          default: "Sort by",
          nameAsc: "Name (A-Z)",
          nameDesc: "Name (Z-A)",
          ratingDesc: "Best Rating",
          ratingAsc: "Worst Rating",
          durationAsc: "Shortest Duration",
          durationDesc: "Longest Duration",
        },
      },
      filters: {
        categories: {
          title: "Categories",
          filterButton: "Filter by Category",
          activeFilters: {
            count: "{count} filter(s) active:",
          },
        },
      },
      card: {
        enrollButton: "Enroll Now",
        hours: "hours",
        reviews: "reviews",
      },
      details: {
        backButton: "Back",
        courseInfo: {
          level: "Level",
          levelValue: "Intermediate",
          duration: "Duration",
          language: "Language",
          languageValue: "English",
          hours: "hours",
          whatsIncluded: "What's included",
          reviews: "reviews",
          features: {
            lifeTimeAccess: "Lifetime Access",
            completionCertificate: "Completion Certificate",
            studentSupport: "Student Support",
          },
          provider: {
            description:
              "Online learning platform that dedicates itself to transforming lives through free and accessible education.",
          },
        },
        tabs: {
          description: "Description",
          reviews: "Reviews",
        },
        content: {
          title: "Course Content",
          loading: "Loading modules...",
        },
        reviews: {
          title: "Student Reviews",
          noReviews: "There are no reviews for this course yet.",
          summary: {
            reviews: "reviews",
            stars: "stars",
            totalRatings: "{count} ratings",
            starsLabel: "{count} stars",
          },
          reviewCard: {
            timeAgo: "{time} ago",
            verified: "Verified Purchase",
          },
        },
      },
      section: {
        title: "COURSES",
        errors: {
          loading: "Error loading data",
        },
      },
    },
    about: {
      title: "About Beharv",
      subtitle: "Transforming Lives Through Quality Education",
      sections: {
        mission: {
          title: "Our Mission",
          content:
            "We are dedicated to democratizing education by providing high-quality, accessible online learning experiences that empower individuals to achieve their professional goals.",
        },
        vision: {
          title: "Our Vision",
          content:
            "To become the leading platform for professional development, creating opportunities for growth and success through innovative educational solutions and industry-recognized certifications.",
        },
        values: {
          title: "Our Values",
          content:
            "Excellence in education, accessibility for all, innovation in learning, and commitment to student success form the foundation of everything we do.",
        },
      },
    },
    contact: {
      title: "Contact Us",
      description:
        "If you have any questions, suggestions or need any assistance, do not hesitate to contact us. Our team is available to answer all your questions and ensure you have the best experience with our courses.",
      methods: {
        title: "Contact us by the following means:",
        email: "E-mail:",
        whatsapp: "Contact via WhatsApp",
      },
    },
    terms: {
      title: "Terms and Conditions",
      sections: {
        introduction: {
          title: "1. Introduction",
          content:
            "Welcome to beharv.com. These Terms and Conditions govern the use of our website. By accessing and using our website, you agree to these Terms and Conditions in full. If you do not agree with any part of these terms, you should not use our website.",
        },
        changes: {
          title: "2. Changes to Terms",
          content:
            "We reserve the right to modify these Terms and Conditions at any time. Changes will be posted on this page, and by continuing to use the site after such changes, you agree to be bound by the modified Terms and Conditions.",
        },
        eligibility: {
          title: "3. Eligibility",
          content:
            "To use our website and services, you must be at least 18 years old or the legal age of majority in your country of residence.",
        },
        account: {
          title: "4. User Account",
          content:
            "To access some of our courses, you may need to create an account. You are responsible for maintaining the confidentiality of your login information and for all activities that occur in your account. You agree to notify us immediately of any unauthorized use of your account.",
        },
        content: {
          title: "5. Course Content",
          content:
            "All course content offered on courses.seucursodigital.com is protected by copyright and may not be reproduced, distributed, or used in any way without the express permission of the copyright owner.",
        },
        payments: {
          title: "6. Payments and Refunds",
          content:
            "All payments made for courses are non-refundable, except as required by law. Make sure to review all course information and confirm that it meets your needs before making a purchase.",
        },
        usage: {
          title: "7. Acceptable Use",
          content:
            "You agree to use our website responsibly and legally. You must not use our website for any illegal or unauthorized purpose. Violation of any acceptable use rules may result in suspension or termination of your account.",
        },
        liability: {
          title: "8. Limitation of Liability",
          content:
            "While we strive to provide accurate and up-to-date information, we do not guarantee that the website content is error-free. We will not be liable for any damages resulting from the use or inability to use our website.",
        },
        thirdParty: {
          title: "9. Third-Party Links",
          content:
            "Our website may contain links to third-party sites. We are not responsible for the content or privacy practices of these sites. You access these links at your own risk.",
        },
        privacy: {
          title: "10. Privacy",
          content:
            "The use of our website is also governed by our Privacy Policy. Please review our Privacy Policy to understand our practices.",
        },
        law: {
          title: "11. Applicable Law",
          content:
            "These Terms and Conditions are governed by the laws of Brazil. Any disputes will be resolved in the competent courts of Brazil.",
        },
        contact: {
          title: "12. Contact",
          content:
            "If you have any questions about these Terms and Conditions, please contact us at support@beharv.com.",
        },
      },
    },
    privacy: {
      title: "Privacy Policy",
      sections: {
        introduction: {
          content:
            "Your privacy is important to us. It is Beharv policy to respect your privacy regarding any information we may collect from you on the Beharv website, and other sites we own and operate.",
        },
        dataCollection: {
          content:
            "We only ask for personal information when we truly need it to provide you with a service. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.",
        },
        cookies: {
          title: "Beharv Cookie Policy",
          subtitle: "What are cookies?",
          content:
            "As is common practice with almost all professional websites, this site uses cookies, which are tiny files downloaded to your computer, to improve your experience. This page describes what information they collect, how we use it, and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored, however, this may downgrade or 'break' certain elements of the site's functionality.",
        },
        userCommitment: {
          title: "User Commitment",
          content:
            "The user commits to make appropriate use of the contents and information that Your Digital Course - COURSES offers on the site and with enunciative, but not limiting, character:",
          list: {
            a: "A) Not to engage in activities that are illegal or contrary to good faith and public order;",
            b: "B) Not to spread propaganda or content of a racist, xenophobic nature, gambling, any type of illegal pornography, terrorism advocacy, or against human rights;",
            c: "C) Not to cause damage to the physical (hardware) and logical (software) systems of Beharv, its suppliers or third parties, to introduce or spread computer viruses or any other hardware or software systems that are capable of causing damage mentioned above.",
          },
        },
        moreInfo: {
          title: "More Information",
          content:
            "Hopefully, that has clarified things for you and as was previously mentioned, if there is something that you aren't sure whether you need or not, it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.",
          effective: "This policy is effective as of May/2022.",
        },
      },
    },
    appBanner: {
      title: "Get the Beharv App",
      description: "Access our courses anytime, anywhere with our mobile app",
      appStore: "Download on the App Store",
      playStore: "Get it on Google Play",
      close: "Close banner",
    },
    certificate: {
      validation: {
        title: "Valid Certificate",
        mainMessage:
          "This certificate was issued by the Beharv platform and is authentic.",
        secureTitle: "Secure Technology",
        secureDescription:
          "Our certificates are issued with technology that ensures their authenticity and can be verified through this unique link.",
        recognitionTitle: "National Recognition",
        recognitionDescription:
          "The Beharv platform is recognized for offering quality courses and valid certificates throughout the national territory.",
        verifiedAt: "Verified on: {date}",
        platformInfo: "Beharv - Quality Online Education",
      },
    },
    common: {
      locale: "en-US",
    },
  },
  es: {
    nav: {
      home: "Inicio",
      courses: "Cursos",
      about: "Nosotros",
      contact: "Contacto",
      login: "Iniciar Sesión",
      register: "Registrarse",
    },
    hero: {
      title: {
        part1: "Desbloquea Tu",
        highlight: "Potencial",
        part2: "Transforma Tu Futuro",
      },
      subtitle:
        "Únete a miles de estudiantes que ya están aprendiendo y avanzando en sus carreras con nuestros cursos profesionales.",
      buttons: {
        explore: "Explorar Cursos",
        learnMore: "Más Información",
      },
      stats: {
        students: "Estudiantes",
        courses: "Cursos",
        successRate: "Tasa de Éxito",
      },
      cards: {
        certification: "Certificación Profesional",
        expertLed: "Cursos con Expertos",
      },
    },
    features: {
      title: "Certificación Profesional",
      onlineLearning: {
        title: "Aprendizaje en Línea",
        description:
          "Estudia a tu propio ritmo desde cualquier parte del mundo",
      },
      certification: {
        title: "Certificación Profesional",
        description: "Recibe un certificado reconocido al completar tus cursos",
      },
      expertInstructors: {
        title: "Instructores Expertos",
        description:
          "Aprende de profesionales de la industria con años de experiencia",
      },
    },
    courseAccess: {
      title: "Comienza Tu Viaje de Aprendizaje",
      subtitle:
        "Accede a nuestra plataforma y descubre una nueva forma de aprender. Con cursos gratuitos y certificados reconocidos, estás a un paso de transformar tu futuro profesional.",
      features: {
        certification: {
          title: "Cursos Certificados",
          description: "Obtén certificados reconocidos",
        },
        pace: {
          title: "Aprende a Tu Ritmo",
          description: "Horario flexible de estudio",
        },
        access: {
          title: "Acceso en Cualquier Lugar",
          description: "Estudia desde cualquier dispositivo",
        },
      },
      button: "Comienza a Aprender Ahora",
    },
    categories: {
      title: "Explora por Categoría",
      subtitle: "Descubre una amplia gama de cursos en diversas categorías",
      items: {
        beauty: {
          title: "Belleza y Estética",
          slug: "belleza-y-estetica",
        },
        health: {
          title: "Salud",
          slug: "salud",
        },
        it: {
          title: "Tecnologías de la Información",
          slug: "tecnologias-de-la-informacion",
        },
        admin: {
          title: "Administración",
          slug: "administracion-y-gestion",
        },
        languages: {
          title: "Idiomas",
          slug: "idiomas",
        },
      },
    },
    reviews: {
      title: "Lo Que Dicen Nuestros Estudiantes",
      subtitle:
        "Descubre cómo nuestros cursos han ayudado a los estudiantes a alcanzar sus metas",
      reviewers: {
        clara: {
          name: "Clara",
          role: "Diseñadora",
          text: "Es el lugar perfecto para cualquiera que quiera invertir en su futuro profesional con confianza.",
        },
        nelson: {
          name: "Nelson",
          role: "Músico",
          text: "¡Ya he tomado 8 cursos en Beharv, y ya estoy pensando en el siguiente!",
        },
        aline: {
          name: "Aline",
          role: "Enfermera",
          text: "Necesitaba especializarme y obtener la certificación, aquí me ayudó mucho.",
        },
      },
    },
    navigation: {
      menu: {
        home: "Inicio",
        courses: "Cursos",
        institutional: "Enlaces Institucionales",
        about: "Nosotros",
        terms: "Términos y Condiciones",
        privacy: "Política de Privacidad",
        contact: "Contacto",
        login: "Iniciar Sesión",
      },
    },
    footer: {
      brand: {
        description:
          "Tu plataforma de cursos online gratuitos. Aprende, desarróllate y alcanza tus metas profesionales.",
      },
      sections: {
        institutional: {
          title: "Enlaces Institucionales",
          about: "Sobre Nosotros",
          terms: "Términos y Condiciones",
          privacy: "Política de Privacidad",
          contact: "Contacto",
        },
        useful: {
          title: "Enlaces Útiles",
          panel: "Panel de Usuario",
          courses: "Lista de Cursos",
          social: "Redes Sociales",
        },
      },
      copyright: "© 2025 Beharv. Todos los derechos reservados.",
    },
    courses: {
      search: {
        placeholder: "Buscar cursos...",
        sortBy: "Ordenar por",
        sortOptions: {
          default: "Ordenar por",
          nameAsc: "Nombre (A-Z)",
          nameDesc: "Nombre (Z-A)",
          ratingDesc: "Mejor Valoración",
          ratingAsc: "Peor Valoración",
          durationAsc: "Menor Duración",
          durationDesc: "Mayor Duración",
        },
      },
      filters: {
        categories: {
          title: "Categorías",
          filterButton: "Filtrar por Categoría",
          activeFilters: {
            count: "{count} filtro(s) activo(s):",
          },
        },
      },
      card: {
        enrollButton: "Inscribirse Ahora",
        hours: "horas",
        reviews: "reseñas",
      },
      details: {
        backButton: "Volver",
        courseInfo: {
          level: "Nivel",
          levelValue: "Intermedio",
          duration: "Duración",
          language: "Idioma",
          languageValue: "Español",
          hours: "horas",
          whatsIncluded: "Qué incluye",
          reviews: "reseñas",
          features: {
            lifeTimeAccess: "Acceso de por vida",
            completionCertificate: "Certificado de finalización",
            studentSupport: "Soporte al estudiante",
          },
          provider: {
            description:
              "Plataforma de aprendizaje en línea que se dedica a transformar vidas a través de la educación gratuita y accesible.",
          },
        },
        tabs: {
          description: "Descripción",
          reviews: "Reseñas",
        },
        content: {
          title: "Contenido del Curso",
          loading: "Cargando módulos...",
        },
        reviews: {
          title: "Reseñas de estudiantes",
          noReviews: "Aún no hay reseñas para este curso.",
          summary: {
            reviews: "reseñas",
            stars: "estrellas",
            totalRatings: "{count} calificaciones",
            starsLabel: "{count} estrellas",
          },
          reviewCard: {
            timeAgo: "hace {time}",
            verified: "Compra verificada",
          },
        },
      },
      section: {
        title: "CURSOS",
        errors: {
          loading: "Error al cargar los datos",
        },
      },
    },
    about: {
      title: "Sobre Beharv",
      subtitle: "Transformando Vidas a Través de la Educación de Calidad",
      sections: {
        mission: {
          title: "Nuestra Misión",
          content:
            "Nos dedicamos a democratizar la educación proporcionando experiencias de aprendizaje en línea accesibles y de alta calidad que empoderan a las personas para alcanzar sus metas profesionales.",
        },
        vision: {
          title: "Nuestra Visión",
          content:
            "Convertirnos en la plataforma líder para el desarrollo profesional, creando oportunidades de crecimiento y éxito a través de soluciones educativas innovadoras y certificaciones reconocidas por la industria.",
        },
        values: {
          title: "Nuestros Valores",
          content:
            "La excelencia en la educación, la accesibilidad para todos, la innovación en el aprendizaje y el compromiso con el éxito de los estudiantes forman la base de todo lo que hacemos.",
        },
      },
    },
    contact: {
      title: "Contáctanos",
      description:
        "Si tienes alguna pregunta, sugerencia o necesitas ayuda, no dudes en contactarnos. Nuestro equipo está disponible para responder todas tus preguntas y asegurar que tengas la mejor experiencia con nuestros cursos.",
      methods: {
        title: "Contáctanos por los siguientes medios:",
        email: "Correo electrónico:",
        whatsapp: "Contactar por WhatsApp",
      },
    },
    terms: {
      title: "Términos y Condiciones",
      sections: {
        introduction: {
          title: "1. Introducción",
          content:
            "Bienvenido a beharv.com. Estos Términos y Condiciones rigen el uso de nuestro sitio web. Al acceder y utilizar nuestro sitio web, aceptas estos Términos y Condiciones en su totalidad. Si no estás de acuerdo con alguna parte de estos términos, no debes utilizar nuestro sitio web.",
        },
        changes: {
          title: "2. Cambios en los Términos",
          content:
            "Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Los cambios se publicarán en esta página, y al continuar utilizando el sitio después de dichos cambios, aceptas estar sujeto a los Términos y Condiciones modificados.",
        },
        eligibility: {
          title: "3. Elegibilidad",
          content:
            "Para utilizar nuestro sitio web y servicios, debes tener al menos 18 años o la mayoría de edad legal en tu país de residencia.",
        },
        account: {
          title: "4. Cuenta de Usuario",
          content:
            "Para acceder a algunos de nuestros cursos, es posible que necesites crear una cuenta. Eres responsable de mantener la confidencialidad de tu información de inicio de sesión y de todas las actividades que ocurran en tu cuenta. Aceptas notificarnos inmediatamente de cualquier uso no autorizado de tu cuenta.",
        },
        content: {
          title: "5. Contenido del Curso",
          content:
            "Todo el contenido del curso ofrecido en courses.seucursodigital.com está protegido por derechos de autor y no puede ser reproducido, distribuido o utilizado de ninguna manera sin el permiso expreso del propietario de los derechos de autor.",
        },
        payments: {
          title: "6. Pagos y Reembolsos",
          content:
            "Todos los pagos realizados por los cursos no son reembolsables, excepto cuando lo exija la ley. Asegúrate de revisar toda la información del curso y confirmar que satisface tus necesidades antes de realizar una compra.",
        },
        usage: {
          title: "7. Uso Aceptable",
          content:
            "Aceptas utilizar nuestro sitio web de manera responsable y legal. No debes utilizar nuestro sitio web para ningún propósito ilegal o no autorizado. La violación de cualquier regla de uso aceptable puede resultar en la suspensión o terminación de tu cuenta.",
        },
        liability: {
          title: "8. Limitación de Responsabilidad",
          content:
            "Si bien nos esforzamos por proporcionar información precisa y actualizada, no garantizamos que el contenido del sitio web esté libre de errores. No seremos responsables de ningún daño resultante del uso o la imposibilidad de usar nuestro sitio web.",
        },
        thirdParty: {
          title: "9. Enlaces de Terceros",
          content:
            "Nuestro sitio web puede contener enlaces a sitios de terceros. No somos responsables del contenido o las prácticas de privacidad de estos sitios. Accedes a estos enlaces bajo tu propio riesgo.",
        },
        privacy: {
          title: "10. Privacidad",
          content:
            "El uso de nuestro sitio web también está regido por nuestra Política de Privacidad. Por favor, revisa nuestra Política de Privacidad para entender nuestras prácticas.",
        },
        law: {
          title: "11. Ley Aplicable",
          content:
            "Estos Términos y Condiciones se rigen por las leyes de Brasil. Cualquier disputa será resuelta en los tribunales competentes de Brasil.",
        },
        contact: {
          title: "12. Contacto",
          content:
            "Si tienes alguna pregunta sobre estos Términos y Condiciones, contáctanos en support@beharv.com.",
        },
      },
    },
    privacy: {
      title: "Política de Privacidad",
      sections: {
        introduction: {
          content:
            "Tu privacidad es importante para nosotros. Es política de Beharv respetar tu privacidad con respecto a cualquier información que podamos recopilar en el sitio web de Beharv y otros sitios que poseemos y operamos.",
        },
        dataCollection: {
          content:
            "Solo solicitamos información personal cuando realmente la necesitamos para proporcionarte un servicio. La recopilamos por medios justos y legales, con tu conocimiento y consentimiento. También te informamos por qué la estamos recopilando y cómo se utilizará.",
        },
        cookies: {
          title: "Política de Cookies de Beharv",
          subtitle: "¿Qué son las cookies?",
          content:
            "Como es práctica común en casi todos los sitios web profesionales, este sitio utiliza cookies, que son pequeños archivos que se descargan en tu computadora, para mejorar tu experiencia. Esta página describe qué información recopilan, cómo la usamos y por qué a veces necesitamos almacenar estas cookies. También compartiremos cómo puedes evitar que estas cookies se almacenen, sin embargo, esto puede degradar o 'romper' ciertos elementos de la funcionalidad del sitio.",
        },
        userCommitment: {
          title: "Compromiso del Usuario",
          content:
            "El usuario se compromete a hacer un uso adecuado de los contenidos e información que Tu Curso Digital - CURSOS ofrece en el sitio y con carácter enunciativo, pero no limitativo:",
          list: {
            a: "A) No realizar actividades que sean ilegales o contrarias a la buena fe y al orden público;",
            b: "B) No difundir propaganda o contenido de naturaleza racista, xenófoba, juegos de azar, cualquier tipo de pornografía ilegal, apología del terrorismo o contra los derechos humanos;",
            c: "C) No causar daños a los sistemas físicos (hardware) y lógicos (software) de Beharv, de sus proveedores o de terceros, para introducir o difundir virus informáticos o cualesquiera otros sistemas de hardware o software que sean capaces de causar los daños mencionados anteriormente.",
          },
        },
        moreInfo: {
          title: "Más Información",
          content:
            "Esperamos que esto haya aclarado las cosas para ti y, como se mencionó anteriormente, si hay algo que no estás seguro de necesitar o no, generalmente es más seguro dejar las cookies habilitadas en caso de que interactúen con una de las funciones que utilizas en nuestro sitio.",
          effective: "Esta política está vigente desde Mayo/2022.",
        },
      },
    },
    appBanner: {
      title: "Obtén la App de Beharv",
      description:
        "Accede a nuestros cursos en cualquier momento y lugar con nuestra aplicación móvil",
      appStore: "Descargar en App Store",
      playStore: "Conseguir en Google Play",
      close: "Cerrar banner",
    },
    certificate: {
      validation: {
        title: "Certificado Válido",
        mainMessage:
          "Este certificado fue emitido por la plataforma Beharv y es auténtico.",
        secureTitle: "Tecnología Segura",
        secureDescription:
          "Nuestros certificados son emitidos con tecnología que garantiza su autenticidad y pueden ser verificados a través de este enlace único.",
        recognitionTitle: "Reconocimiento Nacional",
        recognitionDescription:
          "La plataforma Beharv es reconocida por ofrecer cursos de calidad y certificados válidos en todo el territorio nacional.",
        verifiedAt: "Verificado el: {date}",
        platformInfo: "Beharv - Educación en Línea de Calidad",
      },
    },
    common: {
      locale: "es-ES",
    },
  },
  "pt-br": {
    nav: {
      home: "Início",
      courses: "Cursos",
      about: "Sobre",
      contact: "Contato",
      login: "Entrar",
      register: "Cadastrar",
    },
    hero: {
      title: {
        part1: "Liberte Seu",
        highlight: "Potencial",
        part2: "Transforme Seu Futuro",
      },
      subtitle:
        "Junte-se a milhares de alunos que já estão aprendendo e avançando em suas carreiras com nossos cursos profissionais.",
      buttons: {
        explore: "Explorar Cursos",
        learnMore: "Saiba Mais",
      },
      stats: {
        students: "Alunos",
        courses: "Cursos",
        successRate: "Taxa de Sucesso",
      },
      cards: {
        certification: "Certificação Profissional",
        expertLed: "Cursos com Especialistas",
      },
    },
    features: {
      title: "Certificação Profissional",
      onlineLearning: {
        title: "Aprendizado Online",
        description: "Estude no seu próprio ritmo de qualquer lugar do mundo",
      },
      certification: {
        title: "Certificação Profissional",
        description:
          "Receba um certificado reconhecido ao concluir seus cursos",
      },
      expertInstructors: {
        title: "Instrutores Especialistas",
        description:
          "Aprenda com profissionais da indústria com anos de experiência",
      },
    },
    courseAccess: {
      title: "Comece Sua Jornada de Aprendizado",
      subtitle:
        "Acesse nossa plataforma e descubra uma nova forma de aprender. Com cursos gratuitos e certificados reconhecidos, você está a um passo de transformar seu futuro profissional.",
      features: {
        certification: {
          title: "Cursos Certificados",
          description: "Obtenha certificados reconhecidos",
        },
        pace: {
          title: "Aprenda no Seu Ritmo",
          description: "Horário flexível de estudo",
        },
        access: {
          title: "Acesso em Qualquer Lugar",
          description: "Estude de qualquer dispositivo",
        },
      },
      button: "Comece a Aprender Agora",
    },
    categories: {
      title: "Explore por Categoria",
      subtitle: "Descubra uma ampla variedade de cursos em diversas categorias",
      items: {
        beauty: {
          title: "Beleza e Estética",
          slug: "beleza-e-estetica",
        },
        health: {
          title: "Saúde",
          slug: "saude",
        },
        it: {
          title: "Tecnologia da Informação",
          slug: "tecnologia-da-informacao",
        },
        admin: {
          title: "Administração",
          slug: "administracao-e-gestao",
        },
        languages: {
          title: "Idiomas",
          slug: "idiomas",
        },
      },
    },
    reviews: {
      title: "O Que Nossos Alunos Dizem",
      subtitle:
        "Descubra como nossos cursos têm ajudado os alunos a alcançar seus objetivos",
      reviewers: {
        clara: {
          name: "Clara",
          role: "Designer",
          text: "É o lugar perfeito para qualquer pessoa que queira investir em seu futuro profissional com confiança.",
        },
        nelson: {
          name: "Nelson",
          role: "Músico",
          text: "Já fiz 8 cursos na Beharv, e já estou pensando no próximo!",
        },
        aline: {
          name: "Aline",
          role: "Enfermeira",
          text: "Eu precisava me especializar e conseguir a certificação, aqui me ajudou muito.",
        },
      },
    },
    navigation: {
      menu: {
        home: "Início",
        courses: "Cursos",
        institutional: "Links Institucionais",
        about: "Sobre Nós",
        terms: "Termos e Condições",
        privacy: "Política de Privacidade",
        contact: "Contato",
        login: "Entrar",
      },
    },
    footer: {
      brand: {
        description:
          "Sua plataforma de cursos online gratuitos. Aprenda, desenvolva-se e alcance seus objetivos profissionais.",
      },
      sections: {
        institutional: {
          title: "Links Institucionais",
          about: "Sobre Nós",
          terms: "Termos e Condições",
          privacy: "Política de Privacidade",
          contact: "Contato",
        },
        useful: {
          title: "Links Úteis",
          panel: "Painel do Usuário",
          courses: "Lista de Cursos",
          social: "Redes Sociais",
        },
      },
      copyright: "© 2025 Beharv. Todos os direitos reservados.",
    },
    courses: {
      search: {
        placeholder: "Buscar cursos...",
        sortBy: "Ordenar por",
        sortOptions: {
          default: "Ordenar por",
          nameAsc: "Nome (A-Z)",
          nameDesc: "Nome (Z-A)",
          ratingDesc: "Melhor Avaliação",
          ratingAsc: "Pior Avaliação",
          durationAsc: "Menor Duração",
          durationDesc: "Maior Duração",
        },
      },
      filters: {
        categories: {
          title: "Categorias",
          filterButton: "Filtrar por Categoria",
          activeFilters: {
            count: "{count} filtro(s) ativo(s):",
          },
        },
      },
      card: {
        enrollButton: "Inscrever-se Agora",
        hours: "horas",
        reviews: "avaliações",
      },
      details: {
        backButton: "Voltar",
        courseInfo: {
          level: "Nível",
          levelValue: "Intermediário",
          duration: "Duração",
          language: "Idioma",
          languageValue: "Português",
          hours: "horas",
          whatsIncluded: "O que está incluído",
          reviews: "avaliações",
          features: {
            lifeTimeAccess: "Acesso vitalício",
            completionCertificate: "Certificado de conclusão",
            studentSupport: "Suporte ao aluno",
          },
          provider: {
            description:
              "Plataforma de aprendizado online que se dedica a transformar vidas através da educação gratuita e acessível.",
          },
        },
        tabs: {
          description: "Descrição",
          reviews: "Avaliações",
        },
        content: {
          title: "Conteúdo do Curso",
          loading: "Carregando módulos...",
        },
        reviews: {
          title: "Avaliações dos alunos",
          noReviews: "Ainda não há avaliações para este curso.",
          summary: {
            reviews: "avaliações",
            stars: "estrelas",
            totalRatings: "{count} avaliações",
            starsLabel: "{count} estrelas",
          },
          reviewCard: {
            timeAgo: "há {time}",
            verified: "Compra verificada",
          },
        },
      },
      section: {
        title: "CURSOS",
        errors: {
          loading: "Erro ao carregar os dados",
        },
      },
    },
    about: {
      title: "Sobre a Beharv",
      subtitle: "Transformando Vidas Através da Educação de Qualidade",
      sections: {
        mission: {
          title: "Nossa Missão",
          content:
            "Nos dedicamos a democratizar a educação fornecendo experiências de aprendizado online acessíveis e de alta qualidade que capacitam as pessoas a alcançar seus objetivos profissionais.",
        },
        vision: {
          title: "Nossa Visão",
          content:
            "Tornar-se a principal plataforma para desenvolvimento profissional, criando oportunidades de crescimento e sucesso através de soluções educacionais inovadoras e certificações reconhecidas pelo mercado.",
        },
        values: {
          title: "Nossos Valores",
          content:
            "Excelência em educação, acessibilidade para todos, inovação no aprendizado e compromisso com o sucesso dos alunos formam a base de tudo o que fazemos.",
        },
      },
    },
    contact: {
      title: "Entre em Contato",
      description:
        "Se você tiver alguma dúvida, sugestão ou precisar de ajuda, não hesite em nos contatar. Nossa equipe está disponível para responder todas as suas perguntas e garantir que você tenha a melhor experiência com nossos cursos.",
      methods: {
        title: "Entre em contato pelos seguintes meios:",
        email: "E-mail:",
        whatsapp: "Contato via WhatsApp",
      },
    },
    terms: {
      title: "Termos e Condições",
      sections: {
        introduction: {
          title: "1. Introdução",
          content:
            "Bem-vindo à beharv.com. Estes Termos e Condições regem o uso do nosso site. Ao acessar e usar nosso site, você concorda com estes Termos e Condições na íntegra. Se você não concordar com qualquer parte destes termos, não deve usar nosso site.",
        },
        changes: {
          title: "2. Alterações nos Termos",
          content:
            "Reservamo-nos o direito de modificar estes Termos e Condições a qualquer momento. As alterações serão publicadas nesta página, e ao continuar usando o site após tais alterações, você concorda em estar vinculado aos Termos e Condições modificados.",
        },
        eligibility: {
          title: "3. Elegibilidade",
          content:
            "Para usar nosso site e serviços, você deve ter pelo menos 18 anos ou a maioridade legal em seu país de residência.",
        },
        account: {
          title: "4. Conta do Usuário",
          content:
            "Para acessar alguns de nossos cursos, você pode precisar criar uma conta. Você é responsável por manter a confidencialidade de suas informações de login e por todas as atividades que ocorrem em sua conta. Você concorda em nos notificar imediatamente sobre qualquer uso não autorizado de sua conta.",
        },
        content: {
          title: "5. Conteúdo do Curso",
          content:
            "Todo o conteúdo do curso oferecido em courses.seucursodigital.com é protegido por direitos autorais e não pode ser reproduzido, distribuído ou usado de qualquer forma sem a permissão expressa do proprietário dos direitos autorais.",
        },
        payments: {
          title: "6. Pagamentos e Reembolsos",
          content:
            "Todos os pagamentos feitos pelos cursos não são reembolsáveis, exceto quando exigido por lei. Certifique-se de revisar todas as informações do curso e confirmar que ele atende às suas necessidades antes de fazer uma compra.",
        },
        usage: {
          title: "7. Uso Aceitável",
          content:
            "Você concorda em usar nosso site de maneira responsável e legal. Você não deve usar nosso site para qualquer finalidade ilegal ou não autorizada. A violação de quaisquer regras de uso aceitável pode resultar na suspensão ou encerramento de sua conta.",
        },
        liability: {
          title: "8. Limitação de Responsabilidade",
          content:
            "Embora nos esforcemos para fornecer informações precisas e atualizadas, não garantimos que o conteúdo do site esteja livre de erros. Não seremos responsáveis por quaisquer danos resultantes do uso ou incapacidade de usar nosso site.",
        },
        thirdParty: {
          title: "9. Links de Terceiros",
          content:
            "Nosso site pode conter links para sites de terceiros. Não somos responsáveis pelo conteúdo ou práticas de privacidade desses sites. Você acessa esses links por sua própria conta e risco.",
        },
        privacy: {
          title: "10. Privacidade",
          content:
            "O uso do nosso site também é regido por nossa Política de Privacidade. Por favor, revise nossa Política de Privacidade para entender nossas práticas.",
        },
        law: {
          title: "11. Lei Aplicável",
          content:
            "Estes Termos e Condições são regidos pelas leis do Brasil. Quaisquer disputas serão resolvidas nos tribunais competentes do Brasil.",
        },
        contact: {
          title: "12. Contato",
          content:
            "Se você tiver alguma dúvida sobre estes Termos e Condições, entre em contato conosco em support@beharv.com.",
        },
      },
    },
    privacy: {
      title: "Política de Privacidade",
      sections: {
        introduction: {
          content:
            "Sua privacidade é importante para nós. É política da Beharv respeitar sua privacidade em relação a qualquer informação que possamos coletar no site da Beharv e outros sites que possuímos e operamos.",
        },
        dataCollection: {
          content:
            "Só solicitamos informações pessoais quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.",
        },
        cookies: {
          title: "Política de Cookies da Beharv",
          subtitle: "O que são cookies?",
          content:
            "Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos baixados no seu computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam, como as usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como você pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos da funcionalidade do site.",
        },
        userCommitment: {
          title: "Compromisso do Usuário",
          content:
            "O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o Seu Curso Digital - CURSOS oferece no site e com caráter enunciativo, mas não limitativo:",
          list: {
            a: "A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé e à ordem pública;",
            b: "B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, jogos de sorte ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;",
            c: "C) Não causar danos aos sistemas físicos (hardware) e lógicos (software) da Beharv, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar os danos anteriormente mencionados.",
          },
        },
        moreInfo: {
          title: "Mais Informações",
          content:
            "Esperamos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.",
          effective: "Esta política está em vigor a partir de Maio/2022.",
        },
      },
    },
    appBanner: {
      title: "Baixe o App da Beharv",
      description:
        "Acesse nossos cursos a qualquer hora, em qualquer lugar com nosso aplicativo móvel",
      appStore: "Baixar na App Store",
      playStore: "Disponível no Google Play",
      close: "Fechar banner",
    },
    certificate: {
      validation: {
        title: "Certificado Válido",
        mainMessage:
          "Este certificado foi emitido pela plataforma Beharv e é autêntico.",
        secureTitle: "Tecnología Segura",
        secureDescription:
          "Nossos certificados são emitidos com tecnologia que garante sua autenticidade e podem ser verificados através deste link único.",
        recognitionTitle: "Reconhecimento Nacional",
        recognitionDescription:
          "A plataforma Beharv é reconhecida por oferecer cursos de qualidade e certificados válidos em todo o território nacional.",
        verifiedAt: "Verificado em: {date}",
        platformInfo: "Beharv - Educação Online de Qualidade",
      },
    },
    common: {
      locale: "pt-BR",
    },
  },
};
