import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { useLanguage } from "../context/LanguageContext";
import logo from "../assets/images/logo-course.png";
import defaultImage from "../assets/images/logo-beharv.png";

const CACHE_KEY = "course_details_cache";
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const getLocalCache = (courseId, language) => {
  try {
    const cached = localStorage.getItem(`${CACHE_KEY}_${courseId}_${language}`);
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
      localStorage.removeItem(`${CACHE_KEY}_${courseId}_${language}`);
    }
  } catch (error) {
    return null;
  }
  return null;
};

const setLocalCache = (courseId, data, language) => {
  try {
    localStorage.setItem(
      `${CACHE_KEY}_${courseId}_${language}`,
      JSON.stringify({
        data,
        timestamp: Date.now(),
      })
    );
  } catch (error) {
    return;
  }
};

export const useCourseDetails = (courseId, slug) => {
  const { language, setLanguage } = useLanguage();
  const [course, setCourse] = useState(() => getLocalCache(courseId, language));
  const [loading, setLoading] = useState(!getLocalCache(courseId, language));
  const [error, setError] = useState(null);

  const getLanguagePaths = () => {
    // Return both paths, with the current language first
    switch (language) {
      case "es":
        return ["es_es", "en_us"];
      case "pt-br":
        return ["pt_br", "pt_br"];
      default:
        return ["en_us", "es_es"];
    }
  };

  useEffect(() => {
    console.log("Starting fetch with:", { courseId, slug, language });
    localStorage.removeItem(`${CACHE_KEY}_${courseId}_${language}`);

    const fetchCourse = async () => {
      try {
        setLoading(true);

        if (courseId) {
          const cachedData = getLocalCache(courseId, language);
          console.log("Checking cache:", { courseId, cachedData });
          if (cachedData) {
            setCourse(cachedData);
            setLoading(false);
            return;
          }
        }

        let courseDoc;
        const languagePaths = getLanguagePaths();
        console.log("Language paths to try:", languagePaths);

        // Try each language path until we find the course
        for (const langPath of languagePaths) {
          console.log(`Trying language path: ${langPath}`);

          if (courseId) {
            console.log("Fetching by courseId:", courseId);
            const baseDoc = await getDoc(doc(db, "cursosMd", courseId));
            console.log("Base doc exists:", baseDoc.exists());

            if (baseDoc.exists()) {
              courseDoc = await getDoc(
                doc(db, "cursosMd", courseId, langPath, "curso")
              );
              console.log(
                `Language-specific doc exists (${langPath}):`,
                courseDoc?.exists()
              );
              if (courseDoc?.exists()) {
                if (langPath === "en_us" && language !== "en")
                  setLanguage("en");
                if (langPath === "es_es" && language !== "es")
                  setLanguage("es");
                break;
              }
            }
          } else if (slug) {
            console.log(`Fetching by slug in ${langPath}:`, slug);
            const coursesRef = collection(db, "cursosMd");
            const coursesSnapshot = await getDocs(coursesRef);
            console.log("Found base courses:", coursesSnapshot.docs.length);

            const langDocs = await Promise.all(
              coursesSnapshot.docs.map((docSnapshot) =>
                getDoc(doc(db, "cursosMd", docSnapshot.id, langPath, "curso"))
              )
            );
            console.log(
              `Found language docs for ${langPath}:`,
              langDocs.length
            );

            const searchSlug = slug.endsWith("-2") ? slug.slice(0, -2) : slug;
            const normalizedSlug = searchSlug
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/[^a-z0-9\s-]/g, "")
              .replace(/\s+/g, "-")
              .replace(/-+/g, "-");

            console.log("Normalized slug:", normalizedSlug);

            for (let i = 0; i < coursesSnapshot.docs.length; i++) {
              const docSnapshot = coursesSnapshot.docs[i];
              const langDoc = langDocs[i];
              console.log(
                `Checking doc in ${langPath}:`,
                i,
                "exists:",
                langDoc.exists()
              );

              if (langDoc.exists()) {
                const courseData = langDoc.data();
                const courseTitle = courseData.nome || courseData.nome || "";
                const courseSlug = courseTitle
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replace(/[^a-z0-9\s-]/g, "")
                  .replace(/\s+/g, "-")
                  .replace(/-+/g, "-");

                console.log(`Comparing slugs in ${langPath}:`, {
                  courseTitle,
                  courseSlug,
                  normalizedSlug,
                });

                if (courseSlug === normalizedSlug) {
                  console.log(`Found matching course in ${langPath}!`);
                  courseDoc = {
                    id: docSnapshot.id,
                    exists: () => true,
                    data: () => ({
                      ...docSnapshot.data(),
                      ...langDoc.data(),
                      idCursoMd: docSnapshot.id,
                    }),
                  };

                  courseId = docSnapshot.id;

                  if (langPath === "en_us" && language !== "en")
                    setLanguage("en");
                  if (langPath === "es_es" && language !== "es")
                    setLanguage("es");
                  break;
                }
              }
            }
            if (courseDoc) break; // Exit language path loop if we found the course
          }
        }

        console.log("Final courseDoc:", {
          exists: courseDoc?.exists(),
          data: courseDoc?.data(),
        });

        console.log("Starting reviews fetch for courseId:", courseDoc.id);

        const avaliacoesRef = collection(db, `cursosMd/${courseId}/avaliacoes`);

        console.log(
          "Fetching reviews from path:",
          `cursosMd/${courseId}/avaliacoes`
        );
        const avaliacoesSnapshot = await getDocs(avaliacoesRef);
        console.log("Found reviews:", avaliacoesSnapshot.docs.length);

        let totalRating = 0;
        let validRatings = 0;
        const reviews = [];

        avaliacoesSnapshot.docs.forEach((avaliacaoDoc) => {
          console.log(
            "Processing review:",
            avaliacaoDoc.id,
            avaliacaoDoc.data()
          );
          const avaliacaoData = avaliacaoDoc.data();
          const nota = Number(avaliacaoData?.nota);

          if (!isNaN(nota) && nota >= 0 && nota <= 5) {
            totalRating += nota;
            validRatings++;

            if (avaliacaoData.descricao) {
              reviews.push({
                id: avaliacaoDoc.id,
                rating: nota,
                description: avaliacaoData.descricao,
                date:
                  avaliacaoData.dataRealizacao ||
                  new Date().toLocaleDateString(),
                userId: avaliacaoData.idUser || "anonymous",
              });
            }
          }
        });

        console.log("Processed reviews:", {
          totalReviews: reviews.length,
          validRatings,
          averageRating: totalRating / validRatings,
          reviews,
        });

        const averageRating = validRatings > 0 ? totalRating / validRatings : 0;

        const formattedCourse = {
          id: courseId || courseDoc.id,
          title: courseDoc.data().nome,
          image: courseDoc.data().bannerImage || defaultImage,
          duration: courseDoc.data().cargaHoraria,
          categories: courseDoc
            .data()
            .categoria.split(/\s+e\s+|\s*,\s*/)
            .map((category) => ({
              id: category,
              name: category,
            })),
          rawCategory: courseDoc.data().categoria,
          description: courseDoc.data().descricao,
          rating: Number(averageRating.toFixed(1)),
          ratingCount: validRatings,
          level: "Beginner",
          language: "English",
          lastUpdated: new Date().toISOString(),
          totalLessons: 0,
          features: [
            "Lifetime access",
            "Completion certificate",
            "Student support",
          ],
          provider: {
            name: "Beharv",
            image: logo,
            description:
              "Online learning platform that dedicates itself to transforming lives through free and accessible education.",
          },
          reviews: reviews.sort((a, b) => {
            const dateA = new Date(
              a.date.split(" ")[0].split("/").reverse().join("-")
            );
            const dateB = new Date(
              b.date.split(" ")[0].split("/").reverse().join("-")
            );
            return dateB - dateA;
          }),
        };

        console.log("Final formatted course:", formattedCourse);
        setLocalCache(courseId, formattedCourse, language);
        setCourse(formattedCourse);
        setLoading(false);

        console.log("Course ID being used:", {
          courseId,
          baseDocId: courseDoc?.id,
          formattedCourseId: formattedCourse.id,
        });
      } catch (err) {
        console.error("Error fetching course:", err);
        setError(err);
        setCourse(null);
      } finally {
        setLoading(false);
      }
    };

    if (courseId || slug) {
      fetchCourse();
    }
  }, [courseId, slug, language, setLanguage]);

  return { course, loading, error };
};
